/* eslint-disable prettier/prettier */
import {
  Wrapper,
  CardsWrapper,
  Card,
  ImageContainer,
  Details,
  Buttons,
  SubscribedCard,
  SvgContainer,
  SubscribedDetails,
  SubDetails,
  SubscriptionInfo
} from "./style"
import useHook from "./useHook"
import { ProSvg, UnselectSvg, SelectedSvg } from "@svgs"
import React, { useEffect } from "react"
import { useState } from "react"
import { Modal, Button } from "react-bootstrap"

const Subscription = () => {
  const [radio, setRadio] = useState({ show: false })
  const [showModal, setShowModal] = useState(false)
  const {
    getPlans,
    plansList,
    activePlan,
    getActivePlan,
    activateSubscription,
    upgradeSubsciption
  } = useHook()
  useEffect(() => {
    getPlans()
    getActivePlan()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const subscribe = () => {
    if (activePlan.id) {
      upgradeSubsciption(radio?.offer?.id)
    } else {
      activateSubscription(radio?.offer?.id)
    }
    setShowModal(false)
  }

  const filteredPlans = plansList?.filter(
    item => item?.id !== activePlan?.plan?.id
  )
  console.log("plansList", plansList)

  return (
    <Wrapper>
      {activePlan.id && (
        <>
          <SubscriptionInfo>
            You are the {activePlan?.plan_title} plan now!
          </SubscriptionInfo>
          <SubscribedCard>
            <SubscribedDetails>
              <div>
                <SvgContainer>
                  <ProSvg />
                </SvgContainer>
                <span className="type">{activePlan?.plan_period_title}</span>
              </div>
              <span className="cancelplan">Cancel Plan</span>
            </SubscribedDetails>
            <SubDetails>
              <span className="type">${activePlan?.plan?.price}</span>
              <span className="description">
                Next billing {activePlan.ended_at}
              </span>
            </SubDetails>
          </SubscribedCard>
          <SubscriptionInfo>Upgrade plan</SubscriptionInfo>
        </>
      )}
      <CardsWrapper>
        {filteredPlans?.map((value, index) => {
          return (
            <Card key={index}>
              <ImageContainer>
                <ProSvg />
              </ImageContainer>
              <Details>
                <span className="type">{value.plan_period_title}</span>
                <span className="type">${value.price}</span>
              </Details>
              {radio.show && radio.id === index ? (
                <SelectedSvg
                  className="selectbox"
                  onClick={() =>
                    setRadio({ show: false, id: index, offer: value })
                  }
                />
              ) : (
                <UnselectSvg
                  className="selectbox"
                  onClick={() => {
                    setRadio({ show: true, id: index, offer: value })
                  }}
                />
              )}
            </Card>
          )
        })}
      </CardsWrapper>
      {!activePlan.id && radio.show ? (
        <Buttons
          onClick={() => {
            setShowModal(true)
          }}
        >
          Pay
        </Buttons>
      ) : (
        radio.show && (
          <Buttons
            onClick={() => {
              setShowModal(true)
            }}
          >
            change
          </Buttons>
        )
      )}
      <Modal
        show={showModal && radio.show}
        onHide={() => setShowModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <div className="modal__body1">
            <div className="modal-description">Please confirm</div>
            <div className="inputdivs"></div>
            <Button
              className="modal_btn_style"
              type="submit"
              onClick={subscribe}
            >
              Subscribe
            </Button>
            <Button className="modalcancel" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Wrapper>
  )
}

export default Subscription
