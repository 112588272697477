import { AUTH_ROUTES_LIST } from "@containers/Auth"
import Home from "@containers/screens/home"
// import PrivacyPolicyPage from "@containers/screens/pages/privacy_policy"
// import TermsAndContionsPage from "@containers/screens/pages/terms_and_conditions"
import { SOCIAL_HANDLER } from "@containers/screens/social_integration"
import { PrivateRoute } from "@src/_components"
import { history } from "@src/_helpers"
import Loader from "@src/sharedComponents/loader"
import React from "react"
import { useSelector } from "react-redux"
import { Router, Route, Switch, Redirect } from "react-router-dom"

const AppRoute = () => {
  // const { user } = useSelector(state => state.authentication)
  const alert = useSelector(state => state.alert)

  return (
    <div className="container-fluid p-0">
      <Loader loading={alert.status} />
      <Router history={history}>
        <Switch>
          <Redirect exact from="/" to="/login" />
          {AUTH_ROUTES_LIST.map((item, index) => {
            return item.protected ? (
              <PrivateRoute key={index} exact {...item} />
            ) : (
              <Route key={index} exact {...item} />
            )
          })}

          {SOCIAL_HANDLER.map((item, index) => {
            return <PrivateRoute key={index} exact {...item} />
          })}
        </Switch>
        <PrivateRoute exact path="/home/:route">
          <Home />
        </PrivateRoute>
        <PrivateRoute exact path="/home">
          <Home />
        </PrivateRoute>
      </Router>
    </div>
  )
}

export { AppRoute as App }
