/* eslint-disable prettier/prettier */
import { athleteService, sharedService } from "@src/_services"
import { errorSwal, succesSwal } from "@src/sharedComponents/swal/Swal"
import { useDispatch, useSelector } from "react-redux"

const useHook = () => {
  const dispatch = useDispatch()

  const { balance } = useSelector(state => state.sharedReducer)
  //   const { companyList } = useSelector(state => state.athleteReducer)

  const isLoading = value => {
    dispatch({ type: "LOADER_STATUS", message: value })
  }

  const getDealsList = async (id) => {
    isLoading(true)
    return await athleteService
    .getUserDeals(`athlete_user=${id}`)
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          let message = ""
          for (const [key, value] of Object.entries(res)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
          return false
        } else {
          return(res.data)
        }
      })
      .catch(function (error) {
        isLoading(false) 
        return false
      })
  }

  return {
    getDealsList,
    balance
  }
}
export default useHook
