/* eslint-disable prettier/prettier */
import { companyService } from "@src/_services"
import { errorSwal } from "@src/sharedComponents/swal/Swal"
import { useState } from "react"
import { useDispatch } from "react-redux"

const useHook = () => {
  const [inputValue, setValue] = useState({})
  const [errorValue, setErrorValue] = useState({})

  const dispatch = useDispatch()

  const isLoading = value => {
    dispatch({ type: "LOADER_STATUS", message: value })
  }

  const inputHandler = e => {
    setErrorValue({ ...errorValue, [e.target.name]: "" })
    setValue({ ...inputValue, [e.target.name]: e.target.value })
  }

  const logoHandler = file => {
    setErrorValue({ ...errorValue, company_logo: "" })
    setValue({ ...inputValue, company_logo: file })
  }

  const onSubmitCompany = async event => {
    event.preventDefault()

    const requiredFields = {
      company_logo: "Upload Company Logo",
      name: "Invalid Company Name",
      industry: "Invalid industry",
      company_email: "Invalid Company Email",
      website_url: "Invalid Website Url",
      phone_number: "Invalid Phone Number",
      business_structure: "please select a business structure",
      country: "Invalid country",
      state: "Invalid state",
      headquarter: "Invalid Headquarter",
      about: "Invalid About Company"
    }

    let errors = {}
    let errorStatus = false
    for (const [key, value] of Object.entries(requiredFields)) {
      if (!!!inputValue[key]) {
        errors[key] = value
        errorStatus = true
      }
    }
    setErrorValue(errors)
    if (!errorStatus) {
      isLoading(true)
      profileUpdate()
    }
  }

  const profileUpdate = async () => {
    let formData = new FormData()

    for (const [key, value] of Object.entries(inputValue)) {
      if (key === "website_url") {
        const url = value.replace("https://", "")
        formData.append(`${key}`, `https://${url}`)
      } else {
        formData.append(`${key}`, value)
      }
    }

    isLoading(true)
    await companyService
      .companyAccountSetup(formData)
      .then(async res => {
        isLoading(false)

        let redirectLink = "instructions"
        localStorage.setItem("stage", redirectLink)
        window.location.href = `/${redirectLink}`

        dispatch(success(res.data))
        localStorage.setItem("user", JSON.stringify(res.data))
      })
      .catch(function (error) {
        isLoading(false)
        catchErrorHandler(error)
        throw error
      })

    function success(data) {
      return { type: "USER_SETUP_COMPLETE", payload: data }
    }
  }

  const catchErrorHandler = error => {
    if (error.response) {
      const res = error.response.data
      let message = ""
      for (const [key, value] of Object.entries(res)) {
        message = message + `${key} : ${JSON.stringify(value)} `
      }
      errorSwal("oops", message)
    } else {
      errorSwal("oops", error)
    }
  }

  return {
    inputValue,
    logoHandler,
    setValue,
    errorValue,
    setErrorValue,
    inputHandler,
    onSubmitCompany
  }
}
export default useHook
