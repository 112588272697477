/* eslint-disable prettier/prettier */

/* eslint-disable jsx-a11y/alt-text */
import {
  Wrapper,
  CardsWrapper,
  CardContainer,
  AcceptRejectWrapper
} from "./styles"
import useDeals from "./useHook"
import imgImg from "@src/assets/images/icons/imgImg.png"
import Dropdown from "@src/sharedComponents/dropdown"
import SwitchTabs from "@src/sharedComponents/switchTabs"
// import del from "@svgs/del.svg"
// import edit from "@svgs/edit.svg"
import { validateURL } from "@utils/helpers"
import React, { useEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap"
// import { FiPlus } from "react-icons/fi"
import { useSelector } from "react-redux"

const MyDeals = () => {
  // Deals
  const { getDealsList } = useDeals()
  const [dealsList, setDealsList] = useState([])
  const { companyData } = useSelector(state => state.athleteReducer)

  useEffect(() => {
    ;(async function fetch() {
      const res =await getDealsList(companyData.id)
      setDealsList(res)
    })()
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const Card = () => {
    const data = dealsList.results
    if (!data) return <div>No Data</div>

    const list = data.filter(item => {
      return item.approval_status === "approved"
    })

    return (
      <>
        {list?.map((item, index) => {
          const { title, featured_image, description, athlete_user } = item

          return (
            <CardContainer key={index}>
              <div
                className="card-header"
                style={{ justifyContent: "space-between" }}
              >
                <div style={{ display: "flex" }}>
                  <img
                    src={athlete_user.profile_picture}
                    className="logo-img"
                  />

                  <div className="card-title">
                    <strong>{`${athlete_user.first_name} ${athlete_user.first_name}`}</strong>
                    <span>{`${athlete_user.email} (Athlete)`}</span>
                  </div>
                </div>
              </div>
              <img src={featured_image} className="main-image" />
              <div className="back_card_body">
                <strong>{title}</strong>
                <p className="bank_para_div" styls={{ textAlign: "center" }}>
                  {description}
                </p>
              </div>
            </CardContainer>
          )
        })}
      </>
    )
  }

  const data = dealsList.results
  if (!data) return <div>No Data</div>
  return (
    <Wrapper>
      <CardsWrapper>
        <Card />
      </CardsWrapper>
    </Wrapper>
  )
}

export default MyDeals
