/* eslint-disable prettier/prettier */
import companyUseHook from "../../../useHook"
import { companyService, sharedService } from "@src/_services"
import { errorSwal } from "@src/sharedComponents/swal/Swal"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

const useHook = (userType, setEditable, companyLogo) => {
  const { user } = useSelector(state => state.authentication)
  const [inputValue, setValue] = useState(
    userType === "owner" ? {} : user.company
  )

  const [errorValue, setErrorValue] = useState({})
  const [mccList, setMccList] = useState([])
  const { profileUpdate } = companyUseHook()
  const dispatch = useDispatch()

  useEffect(() => {
    if (userType === "owner") {
      getOwnerDetails()
    } else {
      sharedService.getMccList().then(res =>
        setMccList(
          res.data.map(item => {
            return { ...item, label: item.name }
          })
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isLoading = value => {
    dispatch({ type: "LOADER_STATUS", message: value })
  }

  const handleResponse = data => {
    isLoading(false)
    setEditable(true)
    let message = ""
    if (data.status >= 400) {
      for (const [key, value] of Object.entries(data)) {
        message = message + `${key} : ${JSON.stringify(value)} `
      }
    } else if (data.response) {
      for (const [key, value] of Object.entries(data.response.data)) {
        message = message + `${key} : ${JSON.stringify(value)} `
      }
    }
    message && errorSwal("oops", message)
  }

  const getOwnerDetails = () => {
    isLoading(true)
    companyService
      .getCompanyOwnerInfo()
      .then(res => {
        isLoading(false)
        if (res.status >= 400) {
          handleResponse(res)
        } else {
          setValue(res.data)
        }
      })
      .catch(function (error) {
        handleResponse(error)
      })
  }

  const inputHandler = e => {
    setErrorValue({ ...errorValue, [e.target.name]: "" })
    setValue({ ...inputValue, [e.target.name]: e.target.value })
  }

  const onSubmitOwner = async event => {
    event.preventDefault()

    if (!inputValue.first_name)
      setErrorValue({ ...errorValue, first_name: "First Name Required" })
    else if (!inputValue.last_name)
      setErrorValue({ ...errorValue, last_name: "Last Name Required" })
    else if (!inputValue.date_of_birth)
      setErrorValue({ ...errorValue, date_of_birth: "Date of Birth Required" })
    else if (!inputValue.ssn_last_4)
      setErrorValue({ ...errorValue, ssn_last_4: "Tax Info Required" })
    else if (!inputValue.city)
      setErrorValue({ ...errorValue, city: "City Name Required" })
    else if (!inputValue.address_line1)
      setErrorValue({
        ...errorValue,
        address_line1: "Street Name Required"
      })
    else if (!inputValue.address_line2)
      setErrorValue({ ...errorValue, address_line2: "Address Required" })
    else if (!inputValue.postal_code)
      setErrorValue({ ...errorValue, postal_code: "Postal Code Required" })
    else {
      isLoading(true)
      companyService
        .putCompanyOwnerInfo(inputValue)
        .then(res => {
          handleResponse(res)
        })
        .catch(function (error) {
          handleResponse(error)
        })
    }
  }

  const onSubmitCompany = async event => {
    event.preventDefault()

    if (!inputValue.tax_id)
      setErrorValue({ ...errorValue, tax_id: "Field Required" })
    else if (!inputValue.website_url)
      setErrorValue({ ...errorValue, website_url: "Field Required" })
    else if (!inputValue.city)
      setErrorValue({
        ...errorValue,
        city: "City Required"
      })
    else if (!inputValue.address_line1)
      setErrorValue({ ...errorValue, address_line1: "Street Required" })
    else if (!inputValue.address_line2)
      setErrorValue({ ...errorValue, address_line2: "Address Required" })
    else if (!inputValue.postal_code)
      setErrorValue({ ...errorValue, postal_code: "Postal Code Required" })
    else if (!inputValue.business_type)
      setErrorValue({ ...errorValue, business_type: "Business Type Required" })
    else {
      isLoading(true)
      callSetupApi(inputValue)
    }
  }

  const callSetupApi = async data => {
    const profileInfo = data
    const userType = user?.user_type
    let formData = new FormData()

    if (typeof companyLogo !== "string") {
      profileInfo.company_logo = companyLogo
    } else {
      delete profileInfo.company_logo
    }
    if (user.stripe_account_created) {
      delete profileInfo.business_structure
    }

    for (const [key, value] of Object.entries(profileInfo)) {
      if (key === "company_logo") {
        formData.append(`${userType}.${key}`, value)
      } else if (key === "website_url") {
        const url = value.replace("https://", "")
        formData.append(`${userType}.${key}`, `https://${url}`)
      } else {
        formData.append(`${userType}.${key}`, value)
      }
    }
    await profileUpdate({ formData, userType })

    isLoading(false)
    setEditable(false)
  }

  return {
    mccList,
    inputValue,
    setValue,
    errorValue,
    setErrorValue,
    onSubmitOwner,
    inputHandler,
    onSubmitCompany
  }
}
export default useHook
