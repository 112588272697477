/* eslint-disable prettier/prettier */

/* eslint-disable no-unused-expressions */

/* eslint-disable jsx-a11y/alt-text */
import useHook from "../../useHook"
import { Wrapper } from "./styles"
import profileHook from "../useHook"
import { profileServices } from "@src/_services"
import rightArrow from "@src/assets/images/angle-right.png"
import "@src/container/Layout/Dashboard/profile.css"
import DatePickerCustom from "@src/sharedComponents/datepicker"
import FileUpload from "@src/sharedComponents/fileupload/FileUpload"
import UserInput from "@src/sharedComponents/userInput"
import { COUNTRIES, US_STATES, CAN_STATES, GENDER } from "@src/utils/data"
import { formatCurreny } from "@src/utils/helpers"
// import dislike from '@svgs/dislike.svg';
import instagram from "@svgs/instagram-small.svg"
// import like from '@svgs/like.svg';
import React, { useState, useEffect, Fragment } from "react"
import { Link } from "react-router-dom"

const USER_DEFAULT = {
  first_name: "",
  last_name: "",
  date_of_birth: "",
  gender: "male",
  phone_number: "",
  country: "US",
  state: "Alabama",
  school: "",
  bio: "",
  profile_picture: ""
}

const Profile = () => {
  const { isAthlete, user, history, profileUpdate } = useHook()
  const { getBalance, balance } = profileHook()
  const [userInfo, setUserInfo] = useState(USER_DEFAULT)
  const [editable, setEditable] = useState(true)
  const [IGdetails, setIGdetails] = useState()
  const [errorList, setErrorList] = useState({})

  useEffect(() => {
    getSocialDetails()
    getBalance()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const { athlete } = user
    const userValues = {
      first_name: user.first_name,
      last_name: user.last_name,
      date_of_birth: athlete?.date_of_birth,
      gender: athlete?.gender ? athlete?.gender : user.gender,
      phone_number: athlete?.phone_number ? athlete?.phone_number : "",
      country: athlete?.country ? athlete?.country : user.country,
      state: athlete?.state ? athlete?.state : user.state,
      school: athlete?.school ? athlete?.school : "",
      bio: athlete?.bio,
      profile_picture: user.profile_picture
    }
    setUserInfo(userValues)
  }, [user])

  const getSocialDetails = async () => {
    const IGdetails = await profileServices.checkInstagramConnect()

    if (IGdetails) {
      if (typeof IGdetails.extra_data === "string") {
        let details
        try {
          const detailsReplace = IGdetails.extra_data.replace(/[']+/g, '"')
          details = JSON.parse(detailsReplace)
        } catch (error) {
          details = {}
        }
        setIGdetails(details)
      }
    }
  }

  const handleChange = async event => {
    const { name, value } = event.target
    setUserInfo({ ...userInfo, [name]: value })
  }

  const onSubmitSignup = async event => {
    event.preventDefault()

    if (!userInfo.first_name)
      setErrorList({ ...errorList, firstnameError: "Invalid First Name" })
    else if (!userInfo.last_name)
      setErrorList({ ...errorList, lastnameError: "Invalid Last Name" })
    else if (!userInfo.school)
      setErrorList({ ...errorList, schoolError: "Invalid School Name" })
    else if (!userInfo.phone_number)
      setErrorList({ ...errorList, phoneError: "Invalid Phone Number" })
    else if (!userInfo.bio)
      setErrorList({ ...errorList, bioError: "Invalid Bio" })
    else {
      callSetupApi()
    }
  }

  const callSetupApi = async () => {
    const { user_type } = user
    const userType = user_type
    let formData = new FormData()

    for (const [key, value] of Object.entries(userInfo)) {
      if (
        key === "first_name" ||
        key === "last_name" ||
        key === "profile_picture"
      ) {
        if (key === "profile_picture") {
          if (typeof value === "object") {
            formData.append(key, value)
          }
        } else {
          formData.append(key, value)
        }
      } else formData.append(`${userType}.${key}`, value)
    }
    profileUpdate({ formData, userType })
    setEditable(false)
  }

  const balanceAmount =
    balance?.pending?.[0]?.amount + balance?.available?.[0]?.amount

  return (
    <Wrapper>
      <div className="profile-card">
        <form className="profile_form" onSubmit={onSubmitSignup}>
          <FileUpload
            isAthlete={isAthlete}
            onselectImage={img => {
              setUserInfo({ ...userInfo, profile_picture: img })
            }}
            fileName={userInfo.profile_picture}
          />
          {/* profile buttons */}
          <div className="profile_social text-white mt-2">
            <div className="social-left">
              <img className="mr-3" src={instagram} alt=" " />
              <div className="media-body">
                <h5 className="my-0">
                  {IGdetails ? IGdetails.username : "Instagram"}
                </h5>
                {IGdetails ? <p className="mb-0">Instagram</p> : ""}
              </div>
            </div>
            <h6
              className="my-0"
              onClick={() =>
                history.push("socials", {
                  from: "/home/profile",
                  show: true
                })
              }
            >
              {IGdetails ? "Unlink" : "Link"}
            </h6>
          </div>
          <div
            className="social-button mt-2 mb-4"
            onClick={() =>
              history.push("/home/socials", {
                from: "profile",
                show: true
              })
            }
          >
            Link Social Media Platforms
          </div>
          <h5 className="text-white ">My Wallet</h5>
          <Link
            to="/home/wallet"
            style={{ width: "100%", textDecoration: "none" }}
          >
            <div className="profile_social">
              <div className="wallet-left text-white">
                <p className="text-muted">Balance</p>
                <h5 className="my-0">
                  {formatCurreny(balanceAmount / 100 || 0)}
                  <span
                    style={{ fontSize: "16px", paddingLeft: "5px" }}
                    className="text-muted my-0 ml-1"
                  >
                    USD
                  </span>
                </h5>
              </div>
              <span className="option_icon">
                <img src={rightArrow} alt="" className="option_img" />
              </span>
            </div>
          </Link>
          {/* profile edit row */}
          <div className="d-flex justify-content-between text-white mt-3">
            <h5 className="mb-0">Personal details {editable}</h5>
            <p
              className="my-0 cursor_default"
              onClick={() => setEditable(!editable)}
            >
              Edit
            </p>
          </div>
          <Fragment>
            <UserInput
              readOnly={editable}
              label={"First Name"}
              id="firstname"
              name={"first_name"}
              error={errorList.firstnameError}
              value={userInfo.first_name}
              onChange={e => {
                handleChange(e)
                setErrorList({ ...errorList, firstnameError: "" })
              }}
            />
            <UserInput
              readOnly={editable}
              label={"Last Name"}
              id="lastname"
              name={"last_name"}
              error={errorList.lastnameError}
              value={userInfo.last_name}
              onChange={e => {
                handleChange(e)
                setErrorList({ ...errorList, lastnameError: "" })
              }}
            />
            <DatePickerCustom
              editable={editable}
              dob={userInfo.date_of_birth}
              selectedDOB={date => (userInfo.date_of_birth = date)}
            />
            <div className="form-floating">
              <select
                className={`form-select ${editable ? "deselect_select" : ""}`}
                name={"gender"}
                id="genderType"
                value={user["athlete.gender"]}
                onChange={handleChange}
              >
                {GENDER.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
              <label htmlFor="genderType">Gender</label>
            </div>
            <div className="form-floating">
              <select
                className={`form-select ${editable ? "deselect_select" : ""}`}
                name={"country"}
                id="countryType"
                value={"userInfo.country"}
                onChange={handleChange}
              >
                {COUNTRIES.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
              <label htmlFor="countryType">Country</label>
            </div>
            <div className="form-floating">
              <select
                className={`form-select ${editable ? "deselect_select" : ""}`}
                name={"state"}
                id="stateType"
                value={userInfo.state}
                onChange={handleChange}
              >
                {userInfo.country === "US"
                  ? US_STATES.map(({ value, label }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))
                  : userInfo.country === "CA"
                  ? CAN_STATES.map(({ value, label }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))
                  : []}
              </select>
              <label htmlFor="stateType">State</label>
            </div>
            <UserInput
              readOnly={editable}
              label={"School"}
              id="schoolType"
              name={"school"}
              error={errorList.schoolError}
              value={userInfo.school}
              onChange={e => {
                handleChange(e)
                setErrorList({ ...errorList, schoolError: "" })
              }}
            />
            <UserInput
              readOnly={editable}
              label={"Phone"}
              id="phoneNum"
              name={"phone_number"}
              error={errorList.phoneError}
              value={userInfo.phone_number}
              onChange={e => {
                handleChange(e)
                setErrorList({ ...errorList, phoneError: "" })
              }}
            />
            <UserInput
              readOnly={editable}
              label={"Bio"}
              id="bioType"
              name={"bio"}
              error={errorList.bioError}
              value={userInfo.bio}
              onChange={e => {
                handleChange(e)
                setErrorList({ ...errorList, bioError: "" })
              }}
            />
          </Fragment>
          <input
            className={`mt-3 ${editable ? "d-none" : ""}`}
            type="submit"
            value="Save"
          />
        </form>
      </div>
    </Wrapper>
  )
}

export default Profile
