import useHook from "./useHook"
import LeftSideBar from "@containers/Layout/guestSideBar"
import DatePickerCustom from "@src/sharedComponents/datepicker"
import FileUpload from "@src/sharedComponents/fileupload/FileUpload"
import UserInput from "@src/sharedComponents/userInput"
import { COUNTRIES, US_STATES, CAN_STATES, GENDER } from "@src/utils/data"
import React, { Fragment } from "react"

const AthleteSetup = () => {
  const {
    errorValue,
    inputHandler,
    onSubmit,
    inputValue,
    onChange,
    setErrorValue
  } = useHook()

  const FIELDS_LIST = [
    {
      label: "First Name",
      id: "first_name",
      name: "first_name",
      value: inputValue?.first_name,
      type: "input"
    },
    {
      label: "Last Name",
      name: "last_name",
      id: "last_name",
      value: inputValue?.last_name,
      type: "input"
    },
    {
      label: "date of birth",
      id: "date_of_birth",
      name: "date_of_birth",
      value: inputValue?.date_of_birth,
      type: "date"
    },
    {
      label: "Gender",
      id: "gender",
      name: "gender",
      list: GENDER,
      value: inputValue?.gender,
      type: "dropdown"
    },
    {
      label: "Country",
      name: "country",
      value: inputValue?.country,
      type: "dropdown",
      id: "countryType",
      list: COUNTRIES
    },
    {
      label: "State",
      name: "state",
      value: inputValue?.state,
      type: "dropdown",
      id: "stateType",
      list: inputValue?.country !== "US" ? CAN_STATES : US_STATES
    },
    {
      label: "school",
      id: "school",
      name: "school",
      value: inputValue?.school,
      type: "input"
    },
    {
      label: "Phone Number",
      id: "phone_number",
      name: "phone_number",
      value: inputValue?.phone_number,
      type: "input"
    },
    {
      label: "Bio",
      id: "bio",
      name: "bio",
      value: inputValue?.bio,
      type: "input"
    }
  ]

  return (
    <div className="mainDiv">
      <LeftSideBar />
      <div className="sign-in">
        <h1>Set up account</h1>

        <FileUpload
          isAthlete
          error={errorValue.profile_picture}
          onselectImage={file => onChange("profile_picture", file)}
        />
        <form className="mb-5" onSubmit={onSubmit}>
          {FIELDS_LIST.map((item, i) => {
            const { type, name } = item

            switch (type) {
              case "dropdown":
                return (
                  <div key={i} className="form-floating">
                    <select
                      className={`form-select`}
                      style={{ marginTop: "20px" }}
                      onChange={inputHandler}
                      {...item}
                    >
                      {item.list.map(({ value, label }, i) => (
                        <option key={i} value={value || undefined}>
                          {label}
                        </option>
                      ))}
                    </select>
                    <label htmlFor={item.id}>{item.label}</label>
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorValue[item.name]}
                    </span>
                  </div>
                )
              case "date":
                return (
                  <Fragment key={i}>
                    <DatePickerCustom
                      selectedDOB={date => onChange(name, date)}
                      error={errorValue[name]}
                      onChange={() => setErrorValue({ [name]: "" })}
                    />
                  </Fragment>
                )

              default:
                return (
                  <Fragment key={i}>
                    <UserInput {...item} onChange={inputHandler} />
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorValue[name]}
                    </span>
                  </Fragment>
                )
            }
          })}
          <input className="mt-3 mb-5" type="submit" value="Continue" />
        </form>
      </div>
    </div>
  )
}
export default AthleteSetup
