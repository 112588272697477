import useHook from "./useHook"
import LeftSideBar from "@containers/Layout/guestSideBar"
import FileUpload from "@src/sharedComponents/fileupload/FileUpload"
import UserInput from "@src/sharedComponents/userInput"
import {
  BUSINESS_STRUCTURE_CHOICES,
  INDUSTRIES,
  COUNTRIES,
  US_STATES,
  CAN_STATES
} from "@src/utils/data"
import React, { Fragment } from "react"

const CompanySetup = () => {
  const { errorValue, inputHandler, onSubmitCompany, inputValue, logoHandler } =
    useHook()

  const FIELDS_LIST = [
    {
      label: "Company Name",
      id: "companyName",
      name: "name",
      value: inputValue?.name
    },
    {
      label: "Industry",
      name: "industry",
      id: "industryType",
      list: INDUSTRIES,
      value: inputValue?.industry
    },
    {
      label: "Company Email",
      id: "companyEmail",
      name: "company_email",
      value: inputValue?.company_email
    },
    {
      label: "Website URL",
      id: "website",
      name: "website_url",

      value: inputValue?.website_url
    },
    {
      label: "Phone Number",
      id: "phoneNum",
      name: "phone_number",

      value: inputValue?.phone_number
    },

    {
      label: "Business Structure *",
      name: "business_structure",
      id: "business_structure",
      list: BUSINESS_STRUCTURE_CHOICES,
      value: inputValue?.business_structure
    },

    {
      label: "Country *",
      name: "country",
      value: inputValue?.country,
      id: "countryType",
      list: COUNTRIES
    },
    {
      label: "State *",
      name: "state",
      value: inputValue?.state,
      id: "stateType",
      list: inputValue?.country !== "US" ? CAN_STATES : US_STATES
    },

    {
      label: "Headquarter *",
      id: "headquarter",
      name: "headquarter",
      value: inputValue?.headquarter
    },

    {
      label: "About Company *",
      id: "about",
      name: "about",
      value: inputValue?.about
    }
  ]

  return (
    <div className="mainDiv">
      <LeftSideBar />
      <div className="sign-in">
        <h1>Set up account</h1>

        <FileUpload
          error={errorValue.company_logo}
          onselectImage={logoHandler}
        />
        <form className="mb-5" onSubmit={onSubmitCompany}>
          {FIELDS_LIST.map((item, i) => {
            return !item.list ? (
              <Fragment key={i}>
                <UserInput {...item} onChange={inputHandler} />
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorValue[item.name]}
                </span>
              </Fragment>
            ) : (
              <div key={i} className="form-floating">
                <select
                  className={`form-select`}
                  style={{ marginTop: "20px" }}
                  onChange={inputHandler}
                  {...item}
                >
                  {item.list.map(({ value, label }, i) => (
                    <option key={i} value={value || undefined}>
                      {label}
                    </option>
                  ))}
                </select>
                <label htmlFor={item.id}>{item.label}</label>
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorValue[item.name]}
                </span>
              </div>
            )
          })}
          <input className="mt-3 mb-5" type="submit" value="Continue" />
        </form>
      </div>
    </div>
  )
}
export default CompanySetup
