import { SaveButton } from "./style"
import useHook from "./useHook"
import UserInput from "@src/sharedComponents/userInput"
import { COUNTRIES, US_STATES, CAN_STATES, GENDER } from "@src/utils/data"
import React from "react"

const Owner = ({ editable, setEditable }) => {
  const { errorValue, inputHandler, onSubmitOwner, inputValue } = useHook(
    "owner",
    setEditable
  )

  const FIELDS_LIST = [
    {
      id: "first_name",
      name: "first_name",
      type: "text",
      label: "First Name *",
      value: inputValue.first_name || ""
    },
    {
      id: "last_name",
      name: "last_name",
      type: "text",
      label: "Last Name *",
      value: inputValue.last_name || ""
    },
    {
      id: "gender",
      name: "gender",
      label: "Gender *",
      list: GENDER,
      value: inputValue.gender || ""
    },
    {
      id: "date_of_birth",
      name: "date_of_birth",
      label: "Date of Birth *",
      type: "date",
      value: new Date(inputValue.date_of_birth) || ""
    },
    {
      id: "job_title",
      name: "job_title",
      type: "text",
      label: "Job Title *",
      value: inputValue.job_title || ""
    },
    {
      id: "phone_number",
      name: "phone_number",
      type: "text",
      label: "Phone Number *",
      value: inputValue.phone_number || ""
    },
    {
      id: "ssn_last_4",
      name: "ssn_last_4",
      type: "text",
      label: "Tax Information (SSN, Last four digits) *",
      value: inputValue.ssn_last_4 || ""
    },
    {
      label: "Country *",
      name: "country",
      id: "country",
      value: inputValue.country || "CAN",
      list: COUNTRIES
    },
    {
      label: "State *",
      name: "state",
      id: "state",
      value: inputValue.state || "",
      list: inputValue?.country === "US" ? US_STATES : CAN_STATES
    },
    {
      id: "city",
      name: "city",
      type: "text",
      label: "City *",
      value: inputValue.city || ""
    },

    {
      id: "address_line1",
      name: "address_line1",
      type: "text",
      label: "Street *",
      value: inputValue.address_line1 || ""
    },
    {
      id: "address_line2",
      name: "address_line2",
      type: "text",
      label: "Address *",
      value: inputValue.address_line2 || ""
    },
    {
      id: "postal_code",
      name: "postal_code",
      type: "text",
      label: "Postal Code *",
      value: inputValue.postal_code || ""
    }
  ]
  return (
    <>
      {FIELDS_LIST.map((item, i) => {
        return !item.list ? (
          <div key={i}>
            <UserInput {...item} onChange={inputHandler} readOnly={editable} />
            <span style={{ color: "red", fontSize: "12px" }}>
              {errorValue[item.name]}
            </span>
          </div>
        ) : (
          <div key={i} className="form-floating">
            <select
              className={`form-select ${editable ? "deselect_select" : ""}`}
              style={{ marginTop: "20px" }}
              onChange={inputHandler}
              {...item}
            >
              {item.list.map(({ value, label }, i) => (
                <option key={i} value={value || undefined}>
                  {label}
                </option>
              ))}
            </select>
            <label htmlFor={item.id}>{item.label}</label>
          </div>
        )
      })}
      {!editable && <SaveButton onClick={onSubmitOwner}>Save</SaveButton>}
    </>
  )
}
export default Owner
