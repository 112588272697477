/* eslint-disable prettier/prettier */
import { profileActions } from "@src/_actions"
import {
  companyService,
  profileServices,
  athleteService,
  sharedService
} from "@src/_services"
import { errorSwal } from "@src/sharedComponents/swal/Swal"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

const useHook = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    campaignNo,
    campaingsList,
    atheleteList,
    atheleteNo,
    likesList,
    athleteDetail,
    plansList,
    activePlan
  } = useSelector(state => state.companyReducer)
  const page_position_store = useSelector(
    state => state.homeReducer.page_position
  )

  const { user } = useSelector(state => state.authentication)
  const profile = useSelector(state => state.profile)

  const isLoading = value => {
    dispatch({ type: "LOADER_STATUS", message: value })
  }

  const getMyCampaignsList = async () => {
    isLoading(true)
    await companyService
      .myCampainsList()
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          let message = ""
          for (const [key, value] of Object.entries(res)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        } else {
          dispatch(success(res.data))
        }
      })
      .catch(function (error) {
        isLoading(false)
        console.log("error", error)
        throw error
      })

    function success(data) {
      return { type: "COMPANY_CAMPAIGN", payload: data }
    }
  }

  const campaignLikeDislike = async (id, option) => {
    isLoading(true)
    await companyService
      .campaignLikeDislike(id, { is_liked: option })
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          let message = ""
          for (const [key, value] of Object.entries(res)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        } else {
          dispatch({ type: "COMPANY_CAMPAING_NO", payload: campaignNo + 1 })
        }
      })
      .catch(function (error) {
        isLoading(false)
        console.log("error", error)
        throw error
      })
  }

  const getAthletesList = async () => {
    isLoading(true)
    await companyService
      .athletesList()
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          let message = ""
          for (const [key, value] of Object.entries(res)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        } else {
          dispatch(success(res.data))
        }
      })
      .catch(function (error) {
        isLoading(false)
        console.log("error", error)
        throw error
      })

    function success(data) {
      return { type: "ATHELETE_LIST", payload: data }
    }
  }

  const athletesLikeDislike = async (id, option) => {
    isLoading(true)
    await companyService
      .athletesLikeDislike(id, { is_liked: option })
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          let message = ""
          for (const [key, value] of Object.entries(res)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        } else {
          dispatch({ type: "ATHELETE_NO", payload: atheleteNo + 1 })
        }
      })
      .catch(function (error) {
        isLoading(false)
        console.log("error", error)
        throw error
      })
  }

  const reset = () => {
    console.log("error")
    dispatch({ type: "ATHELETE_NO", payload: 0 })
    dispatch({ type: "COMPANY_CAMPAING_NO", payload: 0 })
  }

  const getCampaignLikesList = async id => {
    isLoading(true)
    await companyService
      .campaignLikesList(id)
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          let message = ""
          for (const [key, value] of Object.entries(res)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        } else {
          if (res.data?.next) {
            await loadMore(res.data.next, res.data)
          } else {
            dispatch(success(res.data))
          }

          function success(data) {
            return { type: "COMPANY_CAMPAIGNS_LIKES_LIST", payload: data }
          }
        }
      })
      .catch(function (error) {
        isLoading(false)
        console.log("error", error)
        throw error
      })
  }

  const loadMore = async (api, data) => {
    let re = await sharedService.apiCall(api)
    let allLikes = { ...data, results: [...data.results, ...re.data.results] }
    
    if (allLikes.data?.next) {
      await loadMore(re.data.next, allLikes)
    } else {
      dispatch(success(allLikes))
    }

    function success(data) {
      return { type: "COMPANY_CAMPAIGNS_LIKES_LIST", payload: data }
    }
  }

  const getAthleteDetail = async id => {
    isLoading(true)
    await companyService
      .athleteDetail(id)
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          let message = ""
          for (const [key, value] of Object.entries(res)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        } else {
          dispatch(success(res.data))
        }
      })
      .catch(function (error) {
        isLoading(false)
        console.log("error", error)
        throw error
      })

    function success(data) {
      updateHeader("profileDetail")
      return { type: "ATHLETE_DETAILS", payload: data }
    }
  }

  const updateHeader = value => {
    dispatch(profileActions.header(value))
  }

  const catchErrorHandler = error => {
    if (error.response) {
      const res = error.response.data
      let message = ""
      for (const [key, value] of Object.entries(res)) {
        message = message + `${key} : ${JSON.stringify(value)} `
      }
      errorSwal("oops", message)
    } else {
      errorSwal("oops", error)
    }
  }

  const newCampaigns = async (payload, callback) => {
    isLoading(true)
    await companyService
      .newCampaigns(payload)
      .then(async res => {
        isLoading(false)
        callback()
        getMyCampaignsList()
       
      })
      .catch(function (error) {
        isLoading(false)
        catchErrorHandler(error)
        throw error
      })
  }

  const profileUpdate = async payload => {
    isLoading(true)
    await profileServices
      .updateProfile(payload)
      .then(async res => {
        isLoading(false)
        dispatch(success(res.data))
        localStorage.setItem("user", JSON.stringify(res.data))
      })
      .catch(function (error) {
        isLoading(false)
        catchErrorHandler(error)
        throw error
      })

    function success(data) {
      return { type: "USER_SETUP_COMPLETE", payload: data }
    }
  }

  const startChat = async data => {
    const payload = { user: data?.user?.id }

    isLoading(true)
    await sharedService
      .startChat(payload)
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          let message = ""
          for (const [key, value] of Object.entries(res)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        } else {
          const chatInfo = {
            image: data.company_logo,
            name: data.name,
            ...res.data
          }

          dispatch({ type: "START_CHAT", payload: chatInfo })
          history.push("/home/chat")
        }
      })
      .catch(function (error) {
        isLoading(false)
        catchErrorHandler(error)
        throw error
      })
  }

  const getMatches = async () => {
    isLoading(true)
    const isBank = page_position_store === "Bank";
    const api = isBank ? `matches/all/` : `campaigns/campaign-likes/?is_liked=true`
    
    await athleteService
      .matchesList(api)
      .then(async res => {
        isLoading(false)
        dispatch(success(res.data))
      })
      .catch(function (error) {
        isLoading(false)
        if (error.response) {
          let message = ""
          for (const [key, value] of Object.entries(error.response.data)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        }
      })

    function success(data) {
      return { type: "MATCHES_LIST", payload: data }
    }
  }
  
  const deleteCompaign = async payload => {
    isLoading(true)
    await companyService
      .deleteCompaign(payload)
      .then(async res => {
        isLoading(false)
        getMyCampaignsList()
      })
      .catch(function (error) {
        isLoading(false)
        if (error.response) {
          let message = ""
          for (const [key, value] of Object.entries(error.response.data)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        }
        // throw error
      })
  }
  const updateCampaign = async data => {
    isLoading(true)

    const payload = {
      title: data.title,
      description: data.description,
      link: data.link
    }
    await companyService
      .updateCompaign(payload, data.id)
      .then(async () => {
        isLoading(false)
        getMyCampaignsList()
      })
      .catch(function (error) {
        isLoading(false)
        if (error.response) {
          let message = ""
          for (const [key, value] of Object.entries(error.response.data)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        }
      })
  }
  const getPlans = async () => {
    isLoading(true)
    await companyService
      .getPlans()
      .then(async res => {
        isLoading(false)
        dispatch(success(res.data))
      })
      .catch(function (error) {
        isLoading(false)
        if (error.response) {
          let message = ""
          for (const [key, value] of Object.entries(error.response.data)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        }
      })

    function success(data) {
      return { type: "PLANS_LIST", payload: data }
    }
  }
  const activateSubscription = async data => {
    isLoading(true)
    const card = JSON.parse(localStorage.getItem("defaultCard"))
    const payload = {
      payment_method: card?.id,
      plan: data
    }
    await companyService
      .activateSubscription(payload)
      .then(async () => {
        isLoading(false)
      })
      .catch(function (error) {
        isLoading(false)
        if (error.response) {
          let message = ""
          for (const [key, value] of Object.entries(error.response.data)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        }
      })
  }
  const getActivePlan = async () => {
    isLoading(true)
    await companyService
      .getActivePlan()
      .then(async res => {
        isLoading(false)
        dispatch(success(res.data))
      })
      .catch(function (error) {
        isLoading(false)
        if (error.response) {
          let message = ""
          for (const [key, value] of Object.entries(error.response.data)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        }
      })

    function success(data) {
      return { type: "ACTIVE_PLAN", payload: data }
    }
  }
  const upgradeSubsciption = async data => {
    isLoading(true)
    const card = JSON.parse(localStorage.getItem("defaultCard"))
    const payload = {
      payment_method: card?.id,
      plan: data
    }
    await companyService
      .upgradeSubsciption(payload)
      .then(async () => {
        isLoading(false)
      })
      .catch(function (error) {
        isLoading(false)
        if (error.response) {
          let message = ""
          for (const [key, value] of Object.entries(error.response.data)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        }
      })
  }
  return {
    getMyCampaignsList,
    campaignLikeDislike,
    getAthletesList,
    athletesLikeDislike,
    isLoading,
    campaingsList,
    atheleteList,
    atheleteNo,
    getCampaignLikesList,
    likesList,
    getAthleteDetail,
    history,
    athleteDetail,
    updateHeader,
    profile,
    page_position_store,
    newCampaigns,
    user,
    profileUpdate,
    reset,
    startChat,
    getMatches,
    deleteCompaign,
    updateCampaign,
    getPlans,
    plansList,
    activateSubscription,
    getActivePlan,
    activePlan,
    upgradeSubsciption
  }
}
export default useHook
