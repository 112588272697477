import { SaveButton } from "./style"
import useHook from "./useHook"
import UserInput from "@src/sharedComponents/userInput"
import {
  BUSSINESS_TYPES,
  BUSINESS_STRUCTURE_CHOICES,
  INDUSTRIES,
  COUNTRIES,
  US_STATES,
  CAN_STATES
} from "@src/utils/data"
import React, { useEffect } from "react"

const Company = ({ companyLogo, editable, setEditable }) => {
  const { errorValue, inputHandler, onSubmitCompany, inputValue, mccList } =
    useHook("company", setEditable, companyLogo)

  const FIELDS_LIST = [
    {
      label: "Company Name *",
      id: "companyName",
      name: "name",
      value: inputValue?.name
    },
    {
      label: "Industry *",
      name: "industry",
      id: "industryType",
      list: INDUSTRIES,
      value: inputValue?.industry
    },
    {
      label: "Company Email *",
      id: "companyEmail",
      name: "company_email",
      value: inputValue?.company_email
    },
    {
      label: "Website URL *",
      id: "website",
      name: "website_url",

      value: inputValue?.website_url
    },
    {
      label: "Phone Number *",
      id: "phoneNum",
      name: "phone_number",

      value: inputValue?.phone_number
    },

    {
      label: "Headquarter *",
      id: "headquarter",
      name: "headquarter",
      value: inputValue?.headquarter
    },
    {
      label: "About Company *",
      id: "about",
      name: "about",
      value: inputValue?.about
    },
    {
      label: "Business Type *",
      name: "business_type",
      id: "business_type",
      list: BUSSINESS_TYPES,
      value: inputValue?.business_type
    },
    {
      label: "Business Structure *",
      name: "business_structure",
      id: "business_structure",
      list: BUSINESS_STRUCTURE_CHOICES,
      value: inputValue?.business_structure
    },
    {
      label: "Phone Number *",
      name: "phone_number",
      id: "phone_number",
      value: inputValue?.phone_number
    },
    {
      label: "Merchant Category Code *",
      name: "business_mcc",
      id: "business_mcc",
      list: mccList,
      value: inputValue?.business_mcc
    },
    {
      label: "Company Tax ID *",
      id: "tax_id",
      name: "tax_id",
      value: inputValue?.tax_id
    },
    {
      label: "Company Privacy Policy Link *",
      id: "website_url",
      name: "website_url",
      value: inputValue?.website_url
    },
    {
      label: "Postal Code *",
      id: "postal_code",
      name: "postal_code",
      value: inputValue?.postal_code
    },
    {
      label: "Country *",
      name: "country",
      value: inputValue?.country,
      id: "countryType",
      list: COUNTRIES
    },
    {
      label: "State *",
      name: "state",
      value: inputValue?.state,
      id: "stateType",
      list: inputValue?.country === "US" ? US_STATES : CAN_STATES
    },
    {
      label: "City *",
      id: "city",
      name: "city",
      value: inputValue?.city
    },
    {
      label: "Street *",
      id: "address_line1",
      name: "address_line1",
      value: inputValue?.address_line1
    },
    {
      label: "Address *",
      id: "address_line2",
      name: "address_line2",
      value: inputValue?.address_line2
    }
  ]

  return (
    <>
      {FIELDS_LIST.map((item, i) => {
        return !item.list ? (
          <div key={i}>
            <UserInput {...item} onChange={inputHandler} readOnly={editable} />
            <span style={{ color: "red", fontSize: "12px" }}>
              {errorValue[item.name]}
            </span>
          </div>
        ) : (
          <div key={i} className="form-floating">
            <select
              className={`form-select ${editable ? "deselect_select" : ""}`}
              style={{ marginTop: "20px" }}
              onChange={inputHandler}
              {...item}
            >
              {item.list.map(({ value, label }, i) => (
                <option key={i} value={value || undefined}>
                  {label}
                </option>
              ))}
            </select>
            <label htmlFor={item.id}>{item.label}</label>
            <span style={{ color: "red", fontSize: "12px" }}>
              {errorValue[item.name]}
            </span>
          </div>
        )
      })}
      {!editable && <SaveButton onClick={onSubmitCompany}>Save</SaveButton>}
    </>
  )
}
export default Company
