/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable no-unused-expressions */

/* eslint-disable jsx-a11y/img-redundant-alt */
import { Item } from "./styles"
import { alertActions, profileActions } from "@src/_actions"
import chatImage from "@src/assets/images/chat1.png"
import chatImage2 from "@src/assets/images/chat2.png"
import homeImage from "@src/assets/images/home1.png"
import homeImage2 from "@src/assets/images/home2.png"
import matchImage from "@src/assets/images/match1.png"
import matchImage2 from "@src/assets/images/match2.png"
import profile_img from "@src/assets/images/profile.png"
import settingsImage from "@src/assets/images/setting1.png"
import settingsImage2 from "@src/assets/images/setting2.png"
import logo_home from "@src/assets/svgs/logo_home.svg"
import "@src/container/Layout/Dashboard/sidebar.css"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

function Sidebar() {
  const box = useRef(null)
  const history = useHistory()
  const { sidebar_status } = useSelector(state => state.alert)
  const { user } = useSelector(state => state.authentication)
  const dispatch = useDispatch()

  const [activeTab, setActiveTab] = useState("home")

  useEffect(() => {
    if (history.location) {
      let path = history.location.pathname
      if (path === "/home") {
        path = path.replace("/", "")
      } else {
        path = path.replace("/home/", "")
      }
      setActiveTab(path)
    }
  }, [])

  useEffect(() => {
    // Function for click event
    function handleOutsideClick(event) {
      if (box.current && !box.current.contains(event.target)) {
        sidebar_status ? dispatch(alertActions.sidebar_hide()) : null
      }
    }

    // Adding click event listener
    document.addEventListener("click", handleOutsideClick)
    return () => document.removeEventListener("click", handleOutsideClick)
  }, [box, sidebar_status])

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const onTabClick = item => {
    let path = `/home/${item}`
    if (item === "home") {
      path = "/home"
    }

    console.log("history.location.pathname", history.location.pathname)
    history.push(path, {
      from: history.location.pathname,
      page: `/${capitalizeFirstLetter(item)}`
    })
    dispatch(profileActions.header(item))
    setActiveTab(item)
  }

  const SideMenuList = [
    {
      title: "Home",
      activeIcon: homeImage,
      inActiveIcon: homeImage2,
      clickHandler: () => onTabClick("home"),
      active: activeTab === "home"
    },

    {
      title: "Match",
      activeIcon: matchImage,
      inActiveIcon: matchImage2,
      clickHandler: () => onTabClick("match"),
      active: activeTab === "match"
    },
    {
      title: "Chat",
      activeIcon: chatImage,
      inActiveIcon: chatImage2,
      clickHandler: () => onTabClick("chat"),
      active: activeTab === "chat"
    },

    {
      title: "Settings",
      activeIcon: settingsImage,
      inActiveIcon: settingsImage2,
      clickHandler: () => onTabClick("settings"),
      active: activeTab === "settings"
    }
  ]

  return (
    <div className="sidebar-wapper" ref={box}>
      <div
        className={`sidebar-container ${
          !sidebar_status ? "sidebar-is-closed" : ""
        } `}
        style={{ height: "100vh" }}
      >
        <i
          className="fa fa-close sidebar-close-icon"
          onClick={() => dispatch(alertActions.sidebar_hide())}
        />
        <div className="sidebar-logo">
          <img src={logo_home} alt=" " />
        </div>
        <div
          className={`sidebar-profile mb-3 py-5 ${
            activeTab === "profile" ? "profile-selected" : ""
          }`}
          onClick={() => onTabClick("profile")}
        >
          <img
            className="media-img rounded-circle mr-3"
            src={user.profile_picture ? user.profile_picture : profile_img}
            alt=" "
          />
          <div className="media-body">
            <h5 className="my-0 text-white">
              {user.first_name} {user.last_name}
            </h5>
            <p className="mb-0 text-muted"> My Profile</p>
          </div>
        </div>
        <div className="sidebar-menues">
          {SideMenuList.map((item, index) => {
            const { clickHandler, inActiveIcon, activeIcon, title, active } =
              item
            return (
              <Item key={index} onClick={clickHandler} active={active}>
                <div>
                  <img src={active ? inActiveIcon : activeIcon} alt="" />
                </div>
                <div className="text">{title}</div>
              </Item>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Sidebar
